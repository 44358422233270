import React, { useEffect, useState } from "react";
import { SmallHeadingStyled } from "../custom/texts/texts.styled";
import { Button, Form, Image, Input, Select, Table } from "antd";
import {
  FlexFiltersContainer,
  ImagePersonTable,
  SectionTitle,
} from "src/pages/teacher-pages/course-detail/course-detail.styled";
import { CardStyled } from "../custom/cards/card.styled";
import { IStudent } from "src/Interfaces/teacherCourses";
import { NoResultsSVG } from "src/assets";
import { Box, Icon } from "@nubeteck/components";
import { fallbackImage } from "src/constants";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

function StudentsList({
  isLoading,
  data,
  seccionId,
  getData,
  download,
  gradesForbidden,
}: {
  isLoading: boolean;
  data: IStudent[];
  seccionId: number;
  gradesForbidden: boolean;
  download: ({
    seccionId,
    nombre,
    estatus,
  }: {
    seccionId: number;
    nombre: string;
    estatus: string;
  }) => void;
  getData: ({
    seccionId,
    nombre,
    estatus,
  }: {
    seccionId: number;
    nombre: string;
    estatus: string;
  }) => void;
}) {
  const navigate = useNavigate();
  const [estudianteNombre, setEstudianteNombre] = useState("");
  const [estudianteEstatus, setEstudianteEstatus] = useState("");

  useEffect(() => {
    getData({ seccionId, nombre: estudianteNombre, estatus: estudianteEstatus ?? "" });
  }, [seccionId, estudianteNombre, estudianteEstatus, getData]);
  const studentsColumns = React.useMemo<ColumnsType<IStudent>>(
    () => [
      {
        title: "Foto",
        dataIndex: "estudianteFoto",
        width: 100,
        render(value) {
          return (
            <ImagePersonTable
              width={50}
              height={50}
              src={value}
              alt="Foto"
              fallback={fallbackImage}
            />
          );
        },
      },
      {
        title: "ID",
        dataIndex: "estudianteMatricula",
        width: 120,
      },
      {
        title: "Estudiante",
        dataIndex: "estudianteNombre",
        width: 230,
      },
      {
        title: "Estatus",
        dataIndex: "estatus",
        width: 70,
      },
      {
        title: "",
        dataIndex: "actions",
        fixed: "right",
        width: 60,
        render: (_, record) =>
          gradesForbidden ? null : (
            <Button
              type="text"
              title="Calificaciones"
              icon={<Icon name="" outlined color="#A7A7A7" />}
              onClick={() =>
                navigate(`/teacher/courses/student-grades/${record.estudianteId}/${seccionId}`)
              }
            />
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      {" "}
      <SectionTitle>Lista de estudiantes</SectionTitle>
      <CardStyled>
        <FlexFiltersContainer style={{ gap: 30 }}>
          <Form.Item name="estatus">
            <Select
              allowClear
              placeholder="Estatus"
              options={[
                { label: "Alta", value: "A" },
                { label: "Baja", value: "D" },
              ]}
              onChange={(e) => setEstudianteEstatus(e)}
            />
          </Form.Item>
          <Form.Item label="Búsqueda de estudiante por nombre o ID">
            <Input
              allowClear
              placeholder="Búsqueda"
              onChange={(e) => setEstudianteNombre(e.target.value)}
            />
          </Form.Item>
          <Button
            onClick={() =>
              download({ seccionId, nombre: estudianteNombre, estatus: estudianteEstatus })
            }
          >
            Exportar
          </Button>
        </FlexFiltersContainer>
        <Table<IStudent>
          locale={{
            emptyText: (
              <Box>
                <Box>
                  <SmallHeadingStyled>No se encontraron estudiantes</SmallHeadingStyled>
                  <Image
                    src={NoResultsSVG}
                    preview={false}
                    alt="Ilustración sin resultados"
                    width={200}
                  />
                </Box>
              </Box>
            ),
          }}
          pagination={false}
          size="small"
          loading={isLoading}
          columns={studentsColumns}
          dataSource={data}
          rowKey={(v) => v.estudianteId}
          scroll={{ x: 500, y: 325 }}
        />
      </CardStyled>
    </>
  );
}

export default StudentsList;
