import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const userServices = createApi({
  reducerPath: "userServices",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURL,
    prepareHeaders: (headers) => {
      const token = localStorage?.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    refreshToken: builder.mutation({
      query: (body) => {
        return {
          url: "Usuarios/Refresh",
          method: "POST",
          body,
        };
      },
    }),
    getUsers: builder.mutation({
      query: (name: string) => {
        return {
          url: `Usuarios?nombre=${name}`,
          method: "GET",
        };
      },
    }),
    createUser: builder.mutation({
      query: (body) => {
        return {
          url: "Usuarios",
          method: "POST",
          body,
        };
      },
    }),
    updateUser: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `Usuarios/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    loginFake: builder.mutation({
      query: (body) => {
        return {
          url: `Usuarios/AuthMatricula?matricula=${body.matricula}`,
          method: "POST",
          body,
        };
      },
    }),
    loginFakeTeacher: builder.mutation({
      query: (body) => {
        return {
          url: `Usuarios/AuthProfesor?correo=${body.correo}`,
          method: "POST",
          body,
        };
      },
    }),
    changeUserState: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `Usuarios/CambiarEstado/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const {
  useRefreshTokenMutation,
  useGetUsersMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useChangeUserStateMutation,
  useLoginFakeMutation,
  useLoginFakeTeacherMutation,
} = userServices;
