import React from "react";
import { useRoutes } from "react-router-dom";
import { MainLayout } from "../components/layout";
import { ProtectedRoute } from "../components/other";
import {
  Calendar,
  Dashboard,
  Payments,
  Registration,
  Reports,
  Subjects,
  DataImports,
  Services,
  Login,
  Users,
  NotFound,
  Reviews,
  Requests,
  RequestsConfig,
  Settings,
  Sponsorships,
  Required,
  FakeLogin,
  ErrorPayment,
  PaymentReceipt,
  ChargesSettings,
  Courses,
} from "../pages";
import AdminWithdraw from "src/pages/admin-pages/adminWithdraw/admin-withdraw";
import { EmailTemplates } from "src/pages/admin-pages/emailTemplates";
import { Sponsors } from "src/pages/admin-pages/sponsors";
import { FakeSelection } from "src/pages/admin-pages/fakeSelection";
import SponsorshipsChanges from "src/pages/admin-pages/sponsorships/sponsorshipsChanges";
import CourseDetail from "src/pages/teacher-pages/course-detail/course-detail";
import CourseGrades from "src/pages/teacher-pages/grades/course-grades";
import CourseAttendance from "src/pages/teacher-pages/attendance/course-attendance";
import HistorialAttendance from "src/pages/teacher-pages/changes-history/attendance-history";
import GradesHitorial from "src/pages/teacher-pages/changes-history/grades-history";
import ActivityGrades from "src/pages/teacher-pages/grades/activity-grades";
import StudentGrades from "src/pages/teacher-pages/grades/student-grades";
import FakeLoginTeachers from "src/pages/admin-pages/fakeLoginTeachers/fakeLoginTeacher";

const navigation = [
  {
    path: "/",
    element: (
      <ProtectedRoute
        allowedRoles={[
          "administrador(a) del sistema",
          "estudiante",
          "cajero",
          "perfil financiero",
          "vicerrectoria",
          "matricula administrativa",
          "profesor",
          "retiro administrativo",
        ]}
      />
    ),
    children: [
      {
        path: "",
        element: <MainLayout />,
        children: [
          {
            path: "",
            element: <Dashboard />,
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["estudiante", "matricula administrativa"]} />,
            children: [
              {
                path: "/subjects-list",
                element: <Subjects />,
              },
              {
                path: "/subjects-registration/:program",
                element: <Registration />,
              },
              {
                path: "/cart/:previous",
                element: <Payments />,
              },
              {
                path: "/calendar",
                element: <Calendar />,
              },
              {
                path: "/reports",
                element: <Reports />,
              },
              { title: "Payment Error", path: "payment-error/:error", element: <ErrorPayment /> },
            ],
          },
          {
            path: "",
            element: (
              <ProtectedRoute
                allowedRoles={["profesor", "estudiante", "matricula administrativa"]}
              />
            ),
            children: [
              {
                path: "/requests",
                element: <Requests />,
              },
            ],
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["director de escuela"]} />,
            children: [
              {
                path: "/admin/teacher-impersonation",
                element: <FakeLoginTeachers />,
              },
            ],
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["vicerrectoria"]} />,
            children: [
              {
                path: "/admin/data-imports",
                element: <DataImports />,
              },
            ],
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["administrador(a) del sistema"]} />,
            children: [
              {
                path: "/admin/requests",
                element: <RequestsConfig />,
              },
              {
                path: "/admin/services",
                element: <Services />,
              },
              {
                path: "/admin/users",
                element: <Users />,
              },
              {
                path: "/admin/sponsors",
                element: <Sponsors />,
              },
              {
                path: "/admin/email-templates",
                element: <EmailTemplates />,
              },
              {
                path: "/admin/settings",
                element: <Settings />,
              },
              {
                path: "/admin/user-impersonation",
                element: <FakeLogin />,
              },
            ],
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["matricula administrativa"]} />,
            children: [
              {
                path: "/admin/user-selection",
                element: <FakeSelection />,
              },
            ],
          },
          {
            path: "",
            element: (
              <ProtectedRoute
                allowedRoles={["retiro administrativo", "administrador(a) del sistema"]}
              />
            ),
            children: [
              {
                path: "/admin/subjects-withdraw",
                element: <AdminWithdraw />,
              },
            ],
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["cajero"]} />,
            children: [
              {
                path: "/admin/charges",
                element: <ChargesSettings />,
              },
            ],
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["cajero", "estudiante"]} />,
            children: [
              {
                path: "/last-receipt/:invoiceId/:peopleId",
                element: <PaymentReceipt />,
              },
            ],
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["perfil financiero"]} />,
            children: [
              {
                path: "/admin/reviews",
                element: <Reviews />,
              },
              {
                path: "/admin/sponsorships",
                element: <Sponsorships />,
              },
              {
                path: "/admin/sponsorships/changes",
                element: <SponsorshipsChanges />,
              },
            ],
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["profesor"]} />,
            children: [
              {
                path: "/teacher/courses",
                element: <Courses />,
              },
              {
                path: "/teacher/courses/:seccionId",
                element: <CourseDetail />,
              },
              {
                path: "/teacher/courses/course-grades/:seccionId",
                element: <CourseGrades />,
              },
              {
                path: "/teacher/courses/activity-grades/:actividadId/:seccionId",
                element: <ActivityGrades />,
              },
              {
                path: "/teacher/courses/course-grades/:seccionId/grades-historial",
                element: <GradesHitorial></GradesHitorial>,
              },
              {
                path: "/teacher/courses/student-grades/:estudianteId/:seccionId",
                element: <StudentGrades />,
              },
              {
                path: "/teacher/courses/course-attendance/:seccionId",
                element: <CourseAttendance />,
              },
              {
                path: "/teacher/courses/course-attendance/:seccionId/historial-attendance",
                element: <HistorialAttendance></HistorialAttendance>,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  { title: "Required", path: "required", element: <Required /> },
  { title: "Not Found", path: "*", element: <NotFound /> },
];

export const RouteElements: React.FC = (): React.ReactElement | null => {
  const element = useRoutes(navigation);
  return element;
};
