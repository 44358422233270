import React from "react";
import { Icon, Image, Modal, Tabs } from "@nubeteck/components";
import { type TabsProps } from "antd";
import { useState } from "react";
import { LargeHeadingStyled, SmallHeadingStyled } from "../../custom/texts/texts.styled";
import ScrollView from "../../custom/scroll-view/scroll-view";
import { useMediaQuery } from "src/hooks";
import { MenuButton } from "src/components/layout/mobile-menu/mobile-menu.style";
import gifMatriculacion from "src/assets/gifs/guide-register.gif";
import gifCalendario from "src/assets/gifs/guide-calendar.gif";
import gifCaja from "src/assets/gifs/guide-cart.gif";
import gifCourses from "src/assets/gifs/guide-courses.gif";
import gifCourseDetail from "src/assets/gifs/guide-course-detail.gif";
import gifActivity from "src/assets/gifs/guide-activity.gif";
import { RootState } from "@/core/store/store";
import { useSelector } from "react-redux";

const UserHelp = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 1180px)");
  const { isATeacher } = useSelector((state: RootState) => state.user);

  const showModal: () => void = () => {
    setIsModalOpen(true);
  };
  const handleOk: () => void = () => {
    setIsModalOpen(false);
  };

  const items: TabsProps["items"] = isATeacher
    ? [
        {
          key: "1",
          label: `Introducción`,
          children: (
            <ScrollView height="40vh">
              <>
                <LargeHeadingStyled>Guía de usuario</LargeHeadingStyled>
                <p>
                  Esta guía de usuario está diseñada para ayudarle a utilizar el sistema de Cenfotec
                  Autoservicio de manera efectiva. Este apartado proporciona los pasos necesarios
                  para visualizar la información de los cursos que imparte, registrar nuevas
                  actividades, y reportar calificaciones y asistencia.
                </p>
              </>
            </ScrollView>
          ),
        },
        {
          key: "2",
          label: `Visualización de cursos`,
          children: (
            <ScrollView height="40vh">
              <>
                <LargeHeadingStyled>Cursos</LargeHeadingStyled>
                <p>
                  El apartado de cursos muestra los cursos impartidos en los periodos actuales, con
                  la posibilidad de filtrar otros cursos dependiendo el período seleccionado en el
                  campo ubicado en la parte superior izquierda de la vista.
                </p>
                <p>
                  Haciendo clíc en las opciones de la parte superior derecha es posible visualizar
                  los cursos en forma de lista, o en mosaicos; también cuenta con la opcion de
                  exportar a Excel la lista de cursos filtrados.
                </p>
                <Image src={gifCourses} alt="Pasos lista de cursos" preview={false} />
                <SmallHeadingStyled>¿Qué opciones tiene disponible cada curso?</SmallHeadingStyled>
                <p>
                  Si se encuentra en la vista de mosaicos, puede acceder al detalle de un curso
                  haciendo clíc en la imagen del mismo. Debajo se muestran las opciones de ver el
                  curso en Moodle, ir a las calificaciones, o ir al reporte de asistencia del curso.
                </p>
                <p>
                  Si se encuentra en la vista tipo lista, las opciones se muestran al dar clíc a los
                  tris puntos ubicados al final del registro.
                </p>
                <Image src={gifCourseDetail} alt="Pasos lista de cursos" preview={false} />
                <SmallHeadingStyled>
                  ¿Qué información contiene el detalle del curso?
                </SmallHeadingStyled>
                <p>
                  En el detalle del curso será posible visualizar la información general del curso,
                  la lista de estudiantes, el horario del curso y las actividades registradas.
                </p>
                <p>
                  Puede navegar hacia las calificaciones de un estudiante haciendo clíc en el ícono{" "}
                  {<Icon name="" outlined color="#A7A7A7" size={13} />} ubicado al final del
                  registro. También puede acceder a las calificaciones de una actividad en concreto
                  haciendo clíc en el mismo ícono en la lista de actividades.
                </p>
                <p>
                  En la lista de actividades ubicada al final del detalle, es posible la creación,
                  edición e inhabilitación de actividades. También se dispone de una opcion de
                  editar los puntos posibles de cada actividad en la lista de forma rápida.
                </p>
              </>
            </ScrollView>
          ),
        },
        {
          key: "3",
          label: `Registro de actividades`,
          children: (
            <ScrollView height="40vh">
              <>
                <LargeHeadingStyled>Registro de actividades</LargeHeadingStyled>
                <p>
                  Al hacer clíc en &apos;Nueva actividad&apos;, se desplegará un formulario a la
                  derecha con los campos necesarios para crear una actividad. Debe tomar en cuenta
                  que al intentar registrar una actividad, si la sumatoria de puntos de las
                  actividades supera los 100 puntos, no será posible guardar la nueva actividad.
                </p>
                <Image src={gifActivity} alt="Registro de actividad" preview={false} />
                <p>
                  El formulario se divide en dos secciones: la información general de la actividad,
                  y los datos asociados a la actividad en Moodle. En caso de que la actividad aún no
                  se encuentre registrada en Moodle, puede dirigirse a la creación de la misma, para
                  el tipo de recurso y semana indicados, haciendo clíc en &apos;Crear
                  actividad&apos;.
                </p>
              </>
            </ScrollView>
          ),
        },
        {
          key: "4",
          label: `Reporte de asistencia`,
          children: (
            <ScrollView height="40vh">
              <>
                <LargeHeadingStyled>Reporte de asistencia</LargeHeadingStyled>
                <p>
                  Para ver el listado de asistencia es necesario seleccionar una fecha. Una vez
                  mostrada la lista de clase, tiene la opcion de realizar una búsqueda por el nombre
                  del estudiante, o modificar los estatus de asistencia de cada estudiante.
                </p>
                <Image src={undefined} alt="Reporte de asistencia" preview={false} />
                <p>
                  Puede seleccionar varios estudiantes marcando las casillas al inicio de cada
                  registro. Una vez hecho esto, se habilitará un campo encima de la lista, en el que
                  puede seleccionar cuál será el estatus de asistencia para los estudiantes
                  seleccionados. Guarde los cambios haciendo clíc en el botón azul en la parte
                  superior derecha de la vista.
                </p>
              </>
            </ScrollView>
          ),
        },
        {
          key: "5",
          label: `Reporte de calificaciones`,
          children: (
            <ScrollView height="40vh">
              <>
                <LargeHeadingStyled>Reporte de calificaciones</LargeHeadingStyled>
                <Image src={undefined} alt="Reporte de calificaciones" preview={false} />
                <p>
                  En cada vista de calificaciones de las que se dispone, puede seleccionar el método
                  de puntuación: &apos;Escala 100&apos; para una calificación porcentual, o
                  &apos;Puntaje&apos; para calificar según el límite de puntos establecidos, Si el
                  botón para guardar los cambios, ubicado encima de la lista a la derecha, se
                  encuentra inhabilitado, es posible que la sumatoria de puntos de las actividades
                  no alcancen los 100 puntos o que el curso no se encuentre activo.
                </p>
                <p>
                  Una vez reportada la calificacion de todos los estudiantes, para cada actividad
                  registrada, tendrá la opción de cerrar el acta de calificaciones.
                </p>
              </>
            </ScrollView>
          ),
        },
      ]
    : [
        {
          key: "1",
          label: `Introducción`,
          children: (
            <ScrollView height="40vh">
              <>
                <LargeHeadingStyled>Guía de usuario</LargeHeadingStyled>
                <p>
                  Esta guía de usuario está diseñada para ayudarle a utilizar el sistema de Cenfotec
                  Autoservicio de manera efectiva. Este apartado proporciona información detallada
                  sobre cómo incribir las materias de un programa, cómo ver los horarios a través
                  del calendario, y cómo generar reportes según la necesidad del usuario. También se
                  proporcionan instrucciones sobre cómo solucionar problemas comunes que puedan
                  surgir al utilizar el sistema.
                </p>
              </>
            </ScrollView>
          ),
        },
        {
          key: "2",
          label: `Matrícula`,
          children: (
            <ScrollView height="40vh">
              <>
                <LargeHeadingStyled>Proceso de matrícula</LargeHeadingStyled>
                <p>
                  La sección para que un estudiante pueda matricularse estará disponible siempre que
                  el período de selección esté abierto. Para comenzar, debe ir al enlace
                  &apos;Matrícula&apos; ubicado en el menú superior.
                </p>
                <p>
                  El primer paso es seleccionar el programa para el cual se realizará la selección
                  de materias. En caso de que no se escoja un programa, se mostrará una advertencia.
                </p>
                <Image src={gifMatriculacion} alt="Pasos matrícula" preview={false} />
                <SmallHeadingStyled>
                  ¿De qué forma puedo escoger las materias que me corresponden?
                </SmallHeadingStyled>
                <p>
                  Las materias que se mostrarán serán solo las que pertenezcan al periodo en el que
                  se encuentra, o que por otros motivos le corresponde cursarla.
                </p>
                <p>
                  Puede elegir desde el listado de materias que se encuentra a la izquierda, para
                  ver la descripción de la materia, y las secciones disponibles.
                </p>
                <p>
                  Para escoger una sección, puede optar por la vista llamada &apos;Oferta&apos;, o
                  puede usar &apos;Oferta en vista de calendario&apos;. Utilizar una vista u otra no
                  afectará el resultado de su selección.
                </p>
                <SmallHeadingStyled>
                  ¿Qué quiere decir una sección que esté subrayada en rojo?
                </SmallHeadingStyled>
                <p>
                  Es común encontrarse con la opción de &apos;Seleccionar&apos; {"(Vista sencilla)"}
                  , o el cuadro de selección {"(Vista tipo calendario)"} marcados con rojo. Esto
                  quiere decir que la sección está disponible, pero su horario interfiere con el de
                  otra materia ya seleccionada. Si hace click ahí, se le pedirá confirmar si desea
                  escoger la sección, pues se removerá de su selección la materia escogida en primer
                  lugar.
                </p>
              </>
            </ScrollView>
          ),
        },
        {
          key: "3",
          label: `Facturación`,
          children: (
            <ScrollView height="40vh">
              <>
                <LargeHeadingStyled>Caja y facturación</LargeHeadingStyled>
                <p>
                  En el apartado de caja se facturan las materias seleccionadas en el proceso de
                  matrícula, los cargos o deudas pendientes con la universidad, y/o los servicios
                  solicitados por usted mismo(a).
                </p>
                <p>
                  En el lado izquierdo podrá observar el listado de asignaturas que se deben
                  facturar, seccionadas por programa, y los servicios obligatorios o solicitados por
                  usted; al lado derecho podrá ubicar el resumen de todos los cargos, descuentos y
                  pagos relacionados a la factura.
                </p>
                <Image src={gifCaja} alt="Pasos matrícula" preview={false} />
                <SmallHeadingStyled>
                  ¿Qué pasa si quiero pagar una cantidad distinta al total de la factura?
                </SmallHeadingStyled>
                <p>
                  Tiene la opción de pagar en cuotas una o varias asignaturas. Para ello, marque la
                  casilla {"pagar en cuotas"} que se muestra en la esquina inferior de cada espacio
                  correspondiente a una asignatura (solo las que aplican).
                </p>
                <p>
                  Si tiene una o mas materias para pagar en cuotas, se habilitará un espacio donde
                  puede escribir el monto que desea pagar inicialmente. El monto digitado no puede
                  ser menor al total que debe pagar por los cargos no asignados para cuotas. Si el
                  monto que digita sobrepasa el el total pendiente, el exceso será abonado a su
                  primera cuota.
                </p>
                <SmallHeadingStyled>
                  He cubierto una parte del pago por medio de una transferencia bancaria, ¿Qué puedo
                  hacer?
                </SmallHeadingStyled>
                <p>
                  En el resumen de su factura encontrará un apartado en el que se muestran la beca o
                  patrocinio que originan un descuento. Si ha realizado una transferencia bancaria
                  para el pago de la factura correspondiente, puede registrarlo haciendo clíc en{" "}
                  {"PAGO POR TRANSFERENCIA"}.{" "}
                </p>{" "}
                <p>
                  Una vez registrada la transferencia no será posible modificar los datos. El aval
                  de la transferencia será revisado, y sus asignaturas seleccionadas quedarán en un
                  estado de espera hasta que se confirmen los datos ingresados.
                </p>
              </>
            </ScrollView>
          ),
        },
        {
          key: "5",
          label: `Calendario`,
          children: (
            <ScrollView height="40vh">
              <>
                <LargeHeadingStyled>Calendario</LargeHeadingStyled>
                <p>
                  En este apartado tendrá acceso al horario de las materias seleccionadas
                  oficialmente. Puede ver el dia, hora de inicio y hora de fin de cada clase,
                  utilizando las vistas por día, por semana y por mes.
                </p>
                <Image src={gifCalendario} alt="Funcionamiento calendario" preview={false} />
              </>
            </ScrollView>
          ),
        },
      ];

  return (
    <>
      {isMobile ? (
        <MenuButton type="text" withIcon icon={<Icon name="help" />} onClick={showModal}>
          Ayuda
        </MenuButton>
      ) : (
        <Icon isTouchable={true} onClick={showModal} name="help" color="white" />
      )}

      <Modal
        title="Guía de usuario"
        open={isModalOpen}
        onOk={handleOk}
        okText="Cerrar"
        cancelButtonProps={{ style: { display: "none" } }}
        width={800}
        onCancel={handleOk}
      >
        <br />
        <Tabs
          defaultActiveKey="1"
          tabPosition={isMobile ? "top" : "left"}
          items={items}
          style={{ height: "45vh" }}
        />
      </Modal>
    </>
  );
};

export default UserHelp;
