import {
  LargeHeadingStyled,
  SectionHeaderStyled,
  SmallHeadingStyled,
} from "src/components/custom/texts/texts.styled";
import { Button, Flex, Image, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect } from "react";
import {
  BelowListContainer,
  DetailGridContainer,
  GeneralInfoDetailContainer,
  RightListContainer,
  RowDetailContainer,
  ScheduleContainer,
  SectionTitle,
  StateFloatContainer,
} from "./course-detail.styled";
import { CardStyled } from "src/components/custom/cards/card.styled";
import { ClosedLockSVG, DetailCourseSVG, NoResultsSVG, OpenLockSVG } from "src/assets";
import { Box } from "@nubeteck/components";
import { ICourseSchedule, ITeacherCourseSchedule } from "src/Interfaces/teacherCourses";
import { ColumnsType } from "antd/es/table";
import {
  useDownloadStudentsMutation,
  useGetCourseActivitiesMutation,
  useGetCourseDetailGeneralMutation,
  useGetCourseScheduleMutation,
  useGetCourseStudentsMutation,
} from "src/services";
import { weekDays } from "src/constants";
import { useNavigate, useParams } from "react-router-dom";
import { ColumnContainer } from "src/components/layout/main-layout/main-layout.styled";
import StudentsList from "src/components/course-detail/students-list";
import ActivitiesList from "src/components/course-detail/activities-list";
import { downloadExcelBase64 } from "src/utils/files-util";

function CourseDetail() {
  const [getCourse, { data: generalData }] = useGetCourseDetailGeneralMutation("");
  const [getCourseActivities, { data: activitiesData, isLoading: isLoadingActivities }] =
    useGetCourseActivitiesMutation("");
  const [getCourseStudents, { data: studentsData, isLoading: isLoadingStudents }] =
    useGetCourseStudentsMutation("");
  const [getCourseSchedule, { data: scheduleData, isLoading: isLoadingSchedule }] =
    useGetCourseScheduleMutation("");
  const [downloadStudents, { isLoading: isLoadingStudentsFile, data: studentsFile }] =
    useDownloadStudentsMutation();

  const navigate = useNavigate();
  const { seccionId } = useParams();

  useEffect(() => {
    getCourse(seccionId);
    getCourseSchedule(seccionId);
  }, [getCourse, getCourseActivities, getCourseSchedule, seccionId]);

  useEffect(() => {
    if (studentsFile) {
      downloadExcelBase64(
        `Lista de estudiantes - ${generalData?.asignaturaNombre} ${generalData?.asignaturaSeccionCodigo}`,
        studentsFile,
      );
    }
  }, [studentsFile, generalData]);

  const scheduleColumns = React.useMemo<ColumnsType<ITeacherCourseSchedule>>(
    () =>
      weekDays.map((day) => ({
        title: day.name == "Miercoles" ? "Miércoles" : day.name,
        dataIndex: day.diaId,
        render: (value: ICourseSchedule) =>
          value && (
            <div>
              <div>{value?.horario}</div>
              <div className="aula">{value?.aula}</div>
            </div>
          ),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <ColumnContainer>
      <SectionHeaderStyled>
        <div>
          <LargeHeadingStyled>
            Detalle del curso {generalData?.anio}/{generalData?.periodo} -{" "}
            {generalData?.asignaturaNombre} ({generalData?.asignaturaSeccionCodigo})
          </LargeHeadingStyled>
        </div>
        <Button onClick={() => history.back()} type="primary">
          Volver atrás
        </Button>
      </SectionHeaderStyled>
      <Content style={{ marginTop: "16px" }}>
        <DetailGridContainer>
          <GeneralInfoDetailContainer>
            <Flex style={{ flexWrap: "wrap", gap: 12, marginBottom: 20 }}>
              <Button
                style={{ width: "46%" }}
                onClick={() => navigate(`/teacher/courses/course-grades/${seccionId}`)}
              >
                Calificaciones
              </Button>
              <Button
                style={{ width: "46%" }}
                onClick={() => navigate(`/teacher/courses/course-attendance/${seccionId}`)}
              >
                Asistencia
              </Button>
            </Flex>
            <CardStyled>
              <Image src={DetailCourseSVG} preview={false} height={62} width={100} />
              <StateFloatContainer>
                <Image
                  src={generalData?.estado === "A" ? OpenLockSVG : ClosedLockSVG}
                  preview={false}
                  alt="ícono candado"
                />
                {generalData?.estado === "A" ? "Curso activo" : "Curso finalizado"}
              </StateFloatContainer>
              <br />
              <div>
                <div className="info-container">
                  <span className="label-info">Sección:</span>
                  <span>{generalData?.asignaturaSeccionCodigo}</span>
                </div>
                <div className="info-container">
                  <span className="label-info">Duración:</span>
                  <span>Desde: {generalData?.duracion.split("-")[0]}</span>
                  <p>Hasta: {generalData?.duracion.split("-")[1]}</p>
                </div>
                <div className="info-container">
                  <span className="label-info">Período:</span>
                  <span>{generalData?.periodo}</span>
                </div>
              </div>
            </CardStyled>
          </GeneralInfoDetailContainer>
          <RightListContainer>
            <StudentsList
              download={downloadStudents}
              getData={getCourseStudents}
              data={studentsData}
              gradesForbidden={activitiesData?.totalPuntosPosibles < 100}
              isLoading={isLoadingStudents || isLoadingStudentsFile}
              seccionId={+(seccionId ?? 0)}
            />
          </RightListContainer>
          <RowDetailContainer>
            <SectionTitle>Horario</SectionTitle>
            <ScheduleContainer>
              {" "}
              <Table<ITeacherCourseSchedule>
                locale={{
                  emptyText: (
                    <Box>
                      <Box>
                        <SmallHeadingStyled>Sin horario disponible</SmallHeadingStyled>
                        <Image
                          src={NoResultsSVG}
                          preview={false}
                          alt="Ilustración sin resultados"
                          width={200}
                        />
                      </Box>
                    </Box>
                  ),
                }}
                pagination={false}
                size="small"
                loading={isLoadingSchedule}
                columns={scheduleColumns}
                dataSource={[scheduleData]}
                rowKey={"1"}
                scroll={{ x: 500, y: 340 }}
              />
            </ScheduleContainer>
          </RowDetailContainer>
          <BelowListContainer>
            <ActivitiesList
              editable={generalData?.estado == "A" && !activitiesData?.estaCalificada}
              getData={getCourseActivities}
              isLoading={isLoadingActivities}
              data={activitiesData}
              seccionId={+(seccionId ?? 0)}
            />
          </BelowListContainer>
        </DetailGridContainer>
      </Content>
    </ColumnContainer>
  );
}

export default CourseDetail;
