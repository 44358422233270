import React from "react";
import { LargeHeadingStyled } from "src/components/custom/texts/texts.styled";
import {
  DownloadContainerStyled,
  SpinnerContainerStyled,
} from "src/pages/student-pages/cart/cart.styled";
import { Button, Card, Flex, Modal, Spin } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import {
  useGetAdobeFilePreviewMutation,
  useGetAdobeRedirectMutation,
} from "src/services/gradesServices";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function GradesPDFPreview({
  opened,
  close,
  seccionId,
}: {
  opened: boolean;
  close: () => void;
  seccionId: string | undefined;
}) {
  const [getAdobePreview, { data: dataPdf, isLoading: isLoadingFile }] =
    useGetAdobeFilePreviewMutation();
  const [openAdobeLink, { isLoading: isLoadingLink }] = useGetAdobeRedirectMutation();
  const [PDFPages, setPDFPages] = React.useState<number>(0);

  React.useEffect(() => {
    if (seccionId && opened) getAdobePreview(seccionId);
  }, [seccionId, opened, getAdobePreview]);

  const onDocLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPDFPages(numPages);
  };

  const getPdfPages = () => {
    const pages: JSX.Element[] = [];
    for (let index = 1; index <= PDFPages; index++) {
      pages.push(
        <Page
          pageNumber={index}
          width={window.innerWidth / 2}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          renderMode="canvas"
        />,
      );
    }
    return pages;
  };

  return (
    <Modal
      open={opened}
      closable={false}
      width="55%"
      footer={
        <Flex gap={10}>
          <Button type="primary" onClick={() => openAdobeLink(seccionId)} loading={isLoadingLink}>
            Firmar acta
          </Button>
          <Button onClick={close}>Cerrar</Button>
        </Flex>
      }
    >
      <DownloadContainerStyled>
        <Card>
          <LargeHeadingStyled>Acta de calificaciones:</LargeHeadingStyled>
          {isLoadingFile && (
            <SpinnerContainerStyled>
              <Spin spinning={isLoadingFile} size="large" />
            </SpinnerContainerStyled>
          )}
          <Document
            file={`data:application/pdf;base64,${dataPdf}`}
            onLoadSuccess={onDocLoadSuccess}
          >
            {getPdfPages()}
          </Document>
        </Card>
      </DownloadContainerStyled>
    </Modal>
  );
}

export default GradesPDFPreview;
